<script setup>
import { useForm } from '@inertiajs/vue3';
import { useDebounceFn } from '@vueuse/core';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

// Components
import Pagination from '@/Components/Pagination/PaginationFE.vue';
import Filters from '@/Components/Table/Filters.vue';
import Table from '@/Components/Table/Table.vue';

// Layouts
import LocationLayout from '@/Layouts/LocationLayout.vue';

const { t } = useI18n();
const route = inject('route');

const props = defineProps({
    query: Object,
    companies: Object,
});

const form = useForm({
    page: props.query.page ?? 1,
});

const getData = (resetPage = true) => {
    form.page = resetPage ? 1 : form.page;

    form.get(route('location.companies.index'), {
        preserveState: true,
        preserveScroll: true,
        only: ['companies', 'query'],
    });
};

const debouncedGetData = useDebounceFn(() => getData(), 300);

const tableSettings = [
    { label: t('Status'), width: '10%' },
    { label: t('Name'), width: '85%', orderBy: 'name' },
    { label: '', width: '5%' },
];

const tableData = computed(() =>
    props.companies.data.map((company) => {
        return {
            id: company.id, // used as vue key if set
            route: route('location.companies.show', company.id), // if set adds arrow link to row
            colorCode: company.status == 'open' ? '#F89B29' : '#1BB21B',
            rowData: [company.status, company.commercial_name],
        };
    })
);

const setPage = (e) => {
    form.page = e;
    getData(false);
};
</script>

<template>
    <LocationLayout :title="$t('Companies')">
        <Filters
            :form="form"
            @anyUpdate="getData()"
            @searchUpdate="debouncedGetData()"
            :h1="$t('Companies')"
            :data="companies"
            :typeFilters="typeFilters"
            :hasSearch="true"
        />
        <template v-if="typeof companies !== 'undefined' && companies.data.length > 0">
            <Table
                class="mb-8"
                :loading="form.processing"
                :tableSettings="tableSettings"
                :data="tableData"
                :footerFaded="query.search != '' && query.search != null"
                v-model:orderBy="form.orderBy"
                @update:orderBy="getData()"
                v-model:orderDir="form.orderDir"
                @update:orderDir="getData()"
            />

            <Pagination
                v-if="typeof companies !== 'undefined'"
                :links="companies.links"
                @setPage="setPage"
                :pageData="companies"
                :hideTotal="query.search != '' && query.search != null"
            />
        </template>
        <template v-else>
            <div class="w-full px-4 py-3 overflow-hidden font-bold leading-tight bg-white rounded">
                {{ $t('No {model} found', { model: $t('companies') }) }}
            </div>
        </template>
    </LocationLayout>
</template>
